export const validationRules = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  required: (value: any) => !!value,
  gt: (min: number) => (value: number) => value > min,
  gte: (min: number) => (value: number) => value >= min,
  lt: (max: number) => (value: number) => value < max,
  lte: (max: number) => (value: number) => value <= max,
  minLength: (minLengthInc: number) => (value: string) => value.length >= minLengthInc,
  maxLength: (maxLengthInc: number) => (value: string) => value.length <= maxLengthInc,
  isNumber: (value: string) => !isNaN(parseInt(value, 10)),
  allowedValue: (allowedValues: string[]) => (value: string) => allowedValues.includes(value),
};

export interface ValidationRule {
  rule: (v: string) => boolean;
  errorMessage: string;
}
