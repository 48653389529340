import { FieldEnums } from './types';
import { ValidationRule, validationRules } from 'validation';
import emailValidator from 'email-validator';

const emailRules: ValidationRule[] = [
  {
    rule: validationRules.required,
    errorMessage: 'Please enter an email address',
  },
  {
    rule: (v) => emailValidator.validate(v as string),
    errorMessage: 'Please enter a valid email',
  },
];

const passwordRules: ValidationRule[] = [
  {
    rule: validationRules.required,
    errorMessage: 'Please enter a password',
  },
];

const rules = {
  [FieldEnums.email]: (v: string) => emailRules.find(({ rule }) => !rule(v)),
  [FieldEnums.password]: (v: string) => passwordRules.find(({ rule }) => !rule(v)),
};

export const validate = (key: FieldEnums, value: string) => rules[key](value);

export const validateAll = (values: {
  [key in keyof typeof FieldEnums]: string;
}) =>
  (Object.keys(rules) as FieldEnums[]).reduce((res, cur) => {
    const result = rules[cur](values[cur]);
    return result ? { ...res, [cur]: result } : res;
  }, {});
