import { useEffect } from 'react';
import Head from 'next/head';
import router from 'next/router';

import { Spinner } from 'src/components';
import { LoginComponent } from 'src/login';
import useFetchData from 'src/hooks/useFetchData';
import { AppStateContextProvider } from 'src/hooks/useAppState';

const LoginPage = () => {
  const { data, loading } = useFetchData({
    url: `/reference-checks/list-filter/`,
  });

  useEffect(() => {
    if (data) {
      router.replace('/');
    }
  }, [data]);

  return (
    <AppStateContextProvider>
      <Head>
        <title>Homeppl Ops</title>
      </Head>

      {loading ? (
        <div className="w-full h-screen flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <LoginComponent />
      )}
    </AppStateContextProvider>
  );
};

export default LoginPage;
