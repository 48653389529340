import { LoginForm } from './login-form';

import Router from 'next/router';
import { useState } from 'react';
import AuthService from 'src/services/authService';

export const LoginComponent = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const login = (params: { email: string; password: string }) => {
    setLoading(true);
    AuthService.login(params).then(
      () => {
        Router.push('/');
      },
      (error) => {
        setLoading(false);

        if (error.response.status === 401) {
          setError('Invalid email and password combination');
        } else {
          setError('An error occured. Please try again later.');
        }
      },
    );
  };

  return (
    <div className="h-screen w-full flex flex-col justify-center bg-gray-100">
      <div className="overflow-hidden w-full h-full absolute">
        <img className="absolute top-[-150px] right-[-150px]" src="/assets/top.svg" />
        <img className="absolute bottom-[-250px] left-[-300px]" src="/assets/bottom.svg" />
      </div>
      <div className="z-10">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src="/assets/homeppl-logomark.svg" alt="Homeppl" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Log in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mx-4">
            <LoginForm onLogin={login} requestError={error} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};
