import { useEffect, useState } from 'react';
import Button from 'src/components/atoms/Button';
import { FormField, Input } from '../components';
import { ValidationRule } from '../utils/validation';
import { FieldEnums } from './types';
import { validate, validateAll } from './validation';

interface LoginFormProps {
  requestError: string;
  loading: boolean;
  onLogin: (values: Record<FieldEnums, string>) => void;
}
const defaultState = {
  [FieldEnums.email]: '',
  [FieldEnums.password]: '',
};

export const LoginForm = ({ requestError, loading, onLogin }: LoginFormProps) => {
  const [values, setValues] = useState<Record<FieldEnums, string>>(defaultState);
  const [errors, setErrors] = useState<Record<FieldEnums, string>>(defaultState);

  useEffect(() => {
    setValues((values) => ({ ...values, [FieldEnums.password]: '' }));
    setErrors((values) => ({ ...values, [FieldEnums.password]: requestError }));
  }, [requestError]);

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationResults = validateAll({
      [FieldEnums.email]: values[FieldEnums.email],
      [FieldEnums.password]: values[FieldEnums.password],
    });

    if (Object.keys(validationResults).length > 0) {
      setErrors(
        Object.keys(validationResults).reduce(
          (res, cur) => ({
            ...res,
            [cur]: (validationResults[cur as keyof typeof validationResults] as ValidationRule)
              .errorMessage,
          }),
          {} as Record<FieldEnums, string>,
        ),
      );
    } else {
      onLogin(values);
    }
  };

  const handleChange = (key: FieldEnums, newValue: string) => {
    if (errors[key] && !validate(key, newValue)) {
      setErrors({ ...errors, [key]: '' });
    }
    setValues({ ...values, [key]: newValue });
  };

  const handleBlur = (key: FieldEnums) => () => {
    const validationResult = validate(key, values[key]);
    if (validationResult) {
      setErrors({ ...errors, [key]: validationResult.errorMessage });
    } else if (errors[key]) {
      setErrors({ ...errors, [key]: '' });
    }
  };
  return (
    <form onSubmit={onSubmit} data-testid="login-form" className="space-y-6">
      <div>
        <FormField
          labelClassName="!font-medium !text-gray-500 !text-base"
          label="Email address"
          error={errors[FieldEnums.email]}
          data-testid={FieldEnums.email}
        >
          <Input
            value={values.email}
            onChange={(e) => handleChange(FieldEnums.email, e.currentTarget.value)}
            onBlur={handleBlur(FieldEnums.email)}
            name="email"
            error={errors[FieldEnums.email]}
            autoComplete="email"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </FormField>
      </div>

      <div>
        <FormField
          labelClassName="!font-medium !text-gray-500 !text-base"
          label="Password"
          error={errors[FieldEnums.password]}
          data-testid={FieldEnums.password}
        >
          <Input
            value={values.password}
            onChange={(e) => handleChange(FieldEnums.password, e.currentTarget.value)}
            onBlur={handleBlur(FieldEnums.password)}
            error={errors[FieldEnums.password]}
            name="password"
            type="password"
            autoComplete="current-password"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </FormField>
      </div>

      <div>
        <Button
          loading={loading}
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Log in
        </Button>
      </div>
    </form>
  );
};
